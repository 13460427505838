<template>
  <div>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Поиск"
      hide-details
      clearable
      class="mb-6"
    ></v-text-field>
    <v-data-table
      :items="items"
      :headers="headersWithClass"
      :search="search"
      :hide-default-header="hideDefaultHeader"
      hide-default-footer
      disable-pagination
      item-key="id"
      @click:row="onRowClick"
    >
      <!--
        Этот шаблон не общий, а только для проектов,
        но я пока не понимаю как расположить его в проектах и передать сюда
      -->
      <template v-slot:[`item.percentReady`]="{ item }">
        <span> {{ item.percentReadyReadable }} </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
/**
 * На v-data-table @click:row не работает модификатор ctrl поэтому приходится изгаляться вручную
 * В будущем было бы здорово переписать стандартно на клик колесиком
 * https://stackoverflow.com/questions/66238952/vuetify-v-data-table-row-click-event-with-modifiers
 */
export default {
  data() {
    return {
      ctrl: false,
    }
  },
  created() {
    window.addEventListener('keydown', this.ctrlHandler)
    window.addEventListener('keyup', this.ctrlHandler)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.ctrlHandler)
    window.removeEventListener('keyup', this.ctrlHandler)
  },
  props: ['headers', 'items', 'rowClickLink', 'hideDefaultHeader'],
  computed: {
    search: {
      get() {
        return this.$store.getters.getProjectSearch
      },
      set(value) {
        this.$store.commit('setProjectSearch', value)
      },
    },
    headerClass() {
      return 'body-1 font-weight-bold'
    },
    headersWithClass() {
      return this.headers.map(h => ({ ...h, class: this.headerClass }))
    },
  },
  methods: {
    onRowClick(item) {
      if (this.ctrl) {
        const route = this.$router.resolve(`${this.rowClickLink}-${item.id}`)
        window.open(route.href, '_blank')
      } else {
        this.$router.push(`${this.rowClickLink}-${item.id}`)
      }
    },
    ctrlHandler(event) {
      if (event.key === 'Control' && event.type === 'keydown') this.ctrl = true
      if (event.key === 'Control' && event.type === 'keyup') this.ctrl = false
    },
  },
}
</script>

<style scoped>
.v-data-table {
  cursor: pointer;
}
.v-input >>> .v-label,
.v-input >>> .v-text-field__slot {
  padding-left: 16px;
}
.v-data-table >>> .v-data-table-header {
  background-color: #eeeeee;
}
</style>
