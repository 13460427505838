<template>
  <div>
    <DisplayLoading
      :isLoading="isLoading"
      :isError="isError"
      :loadingMessage="loadingMessage"
      :errorMessage="errorMessage"
    >
      <div v-if="pageData">
        <PageHeader text="Пользователи">
          <v-switch
            label="Показать регионы"
            class="mr-6"
            v-model="showRegion"
          ></v-switch>
          <AddButton buttonText="Добавить пользователя" />
        </PageHeader>
        <TableWithSearch
          :headers="headers"
          :items="preparedUsers"
          :rowClickLink="rowClickLink"
        />
      </div>
      <div v-else>Нет пользователей</div>
    </DisplayLoading>
  </div>
</template>

<script>
import AddButton from '@/components/buttons/AddButton.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import TableWithSearch from '@/components/common/TableWithSearch.vue'

import { loadPageData } from '@/mixins/loadPageData'

import { ADMIN, USERS, USER_SHORT } from '@/store/const/path'
import { API_USERS } from '@/store/const/apiPaths'
import {
  USER_ROLES,
  USER_HIDE_ADMIN_LOGIN,
  USER_REGION_ID,
} from '@/store/const/userRoles'

export default {
  data() {
    return {
      showRegion: false,
      loadingMessage: 'Загружаю пользователей',
      loadUrl: API_USERS,
      errorMessage: 'Не удалось загрузить пользователей',
      headers: [
        { text: 'ФИО', value: 'fio', width: 450 },
        { text: 'Роль', value: 'role', width: 150 },
        { text: 'Профили', value: 'profile' },
      ],
    }
  },
  mixins: [loadPageData],
  components: {
    AddButton,
    PageHeader,
    DisplayLoading,
    TableWithSearch,
  },
  computed: {
    users() {
      return this.pageData
    },
    rowClickLink() {
      return `/${ADMIN}/${USERS}/${USER_SHORT}`
    },
    preparedUsers() {
      return this.users
        .filter(u => u.login !== USER_HIDE_ADMIN_LOGIN)
        .filter(u => (this.showRegion ? u : u.role_id !== USER_REGION_ID))
        .sort((a, b) => (a.fio > b.fio ? 1 : -1))
        .map(u => ({
          id: u.id,
          fio: u.fio,
          role: USER_ROLES[u.role.id].display,
          profile: u.profiles.map(p => p.name).join(', '),
        }))
    },
  },
}
</script>
