<template>
  <v-btn text small @click="onAdd"
    ><v-icon class="mr-1" large>mdi-plus</v-icon> {{ buttonText }}
  </v-btn>
</template>

<script>
import { ADD } from '@/store/const/path'

export default {
  props: { buttonText: { default: 'Добавить' } },
  methods: {
    onAdd() {
      this.$router.push(`${this.$route.path}/${ADD}`)
    },
  },
}
</script>
